<template>
  <div>
    <template>

      <!-- Items -->
      <ws-data-table
          v-if="items.length > 0"
          :headers="headers"
          :items="items"
          :row-action="($event) => { openEditEntity($event) }"
          disable-pagination
      >

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">
            {{ item.name }}
          </h5>
        </template>

        <template #item.action="{item}">
          <v-btn @click.stop="openDeleteDialog(item)" icon :color="wsACCENT">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>

        <template #footer>
          <div class="pl-4">
            <h5 @click="openAddEntity" class="pointer" :style="`color : ${wsACCENT}`">
              <v-icon>mdi-plus</v-icon>
              <span>
                  {{ $t('AddDocumentType') }}
              </span>
            </h5>


          </div>


        </template>

      </ws-data-table>

      <!-- No Content -->
      <div v-else-if="!loading" style="height: 400px" class="d-flex align-center justify-center">

        <div>
          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsACCENT">mdi-cog</v-icon>
          </div>
          <h3 class="text-center mt-3" style="max-width: 450px">{{ $t('wsu.settings.enrollment.documents.no_items.title') }}</h3>
          <h4 class="text-center font-weight-regular" style="max-width: 550px">{{ $t('wsu.settings.enrollment.documents.no_items.description') }}</h4>
          <div class="d-flex justify-center mt-3">
            <ws-button
                label="AddDocument"
                @click="openAddEntity"
            />
          </div>
        </div>

      </div>


    </template>


    <template>
      <!-- Add Edit Entity Dialog-->
      <ws-dialog
          v-model="displayDialog"
          @save="addEditEntity"
          :title="newEntity ? $t('AddDocumentType') : $t('EditDocumentType')"
          :show-delete="!newEntity"
          @delete="deleteEntity"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('wsu.settings.enrollment.documents.document_type.placeholder')"
            :error="nameError"
            @input="nameError = false"
            class="mt-5"

        />
        <v-sheet height="20" />
        <ws-switch
            v-model="entityData.is_native"
            :placeholder="$t('wsu.settings.enrollment.documents.native.title')"
            :tooltip="$t('wsu.settings.enrollment.documents.native.tooltip')"
            :error="typeError"
            @input="typeError = false"
        />
        <ws-switch
            v-model="entityData.is_foreign"
            :placeholder="$t('wsu.settings.enrollment.documents.foreign.title')"
            :tooltip="$t('wsu.settings.enrollment.documents.foreign.tooltip')"
            :error="typeError"
            @input="typeError = false"
        />
        <ws-switch
            v-model="entityData.is_enrollment_education"
            :placeholder="$t('wsu.settings.enrollment.documents.education_document.title')"
            :tooltip="$t('wsu.settings.enrollment.documents.education_document.tooltip')"
        />
      </ws-dialog>
      <!-- Delete Entity Dialog-->
      <ws-dialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteDocumentType')"
          @delete="deleteEntity"
          display-confirm-delete
          close-delete
      >

      </ws-dialog>
    </template>


  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "enrollmentSettingsWaves",
  data() {
    return {
      items : [],
      loading : true,

      entityData : {},
      newEntity : true,
      displayDialog : false,
      displayDeleteDialog : false,

      nameError : false,
      typeError : false
    }
  },
  computed : {
    ...mapState('wsu_documents' , ['selectedLang']),

    headers() {
      return [
        { text : this.$t('Name')  , value : 'name' },
        {  value : 'action' , sortable : false , width : 10}
      ]
    }
  },
  methods : {
    ...mapActions('wsu_documents' , [
      'GET_ENROLLMENT_DOCUMENT_TYPES',
      'ADD_EDIT_DOCUMENT_TYPE',
      'DELETE_DOCUMENT_TYPE'
    ]),

    async addEditEntity() {
      if ( !this.entityData.name ) {
        this.nameError = true
        this.notify(this.$t('EnterName') , 'warning')
        return
      }

      if ( !this.entityData.native && this.entityData.foreign  ) {
        this.typeError = true
        this.notify(this.$t('wsu.settings.enrollment.documents.select_native_or_foreign') , 'warning')
        return
      }

      this.entityData.lang = this.selectedLang

      let result = await this.ADD_EDIT_DOCUMENT_TYPE(this.entityData)

      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }

      if ( this.newEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.settings.enrollment.documents.created') , 'success')
      } else {
        let index = this.items.findIndex( el => el.uuid  === this.entityData.uuid )
        if ( index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }
      this.displayDialog = false

    },
    async deleteEntity() {
      let result = await this.DELETE_DOCUMENT_TYPE(this.entityData.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }
      let index = this.items.findIndex( el => el.uuid  === this.entityData.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.displayDialog = false
      this.displayDeleteDialog = false
    },

    openAddEntity() {
      this.entityData = {}
      this.newEntity = true
      this.displayDialog = true
    },
    openEditEntity(item) {
      this.entityData = this.COPY(item)
      this.newEntity = false
      this.displayDialog = true
    },
    openDeleteDialog(item) {
      this.entityData = this.COPY(item)
      this.displayDeleteDialog = true
    },
    async initPage() {
      this.loading = true

      let result = await this.GET_ENROLLMENT_DOCUMENT_TYPES()

      if ( !result ) {
        return
      }


      this.items = result.documents
      this.loading = false
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>