<template>
  <sub-page
      :title="$t('General')"
      icon="mdi-cogs"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <!-- Actions-->

      <!-- Items-->
      <template #item.waves>
        <enrollment-settings-waves />
      </template>

      <template #item.programs>
        <enrollment-settings-programs />
      </template>

      <template #item.documents>
        <enrollment-settings-documents />
      </template>

    </ws-accordion>


  </sub-page>
</template>

<script>

import enrollmentSettingsDocuments from "@/components/university/settings/enrollmentSettingsDocuments";
import enrollmentSettingsWaves from "@/components/university/settings/enrollmentSettingsWaves";
import enrollmentSettingsPrograms from "@/components/university/settings/enrollmentSettingsPrograms";
import {mapActions} from "vuex";
export default {
  name: "settingsSite",
  components : {
    enrollmentSettingsDocuments,
    enrollmentSettingsWaves,
    enrollmentSettingsPrograms
  },
  data() {
    return {
      serviceKeys : {},
      // alias
      alias : '',
      aliasError : null,
      aliasConfirmationError : null,
      aliasConfirmationCode : null,
      aliasConfirmation : null,
      displayAliasDialog : false,
      // description
      langs  : [],
      names : {},
      descriptions : {}
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('wsu.settings.enrollment.waves.title') ,
          subtitle : this.$t('wsu.settings.enrollment.waves.description'),
          value : 'waves' ,
          icon : 'mdi-calendar',
          expand : false,
          expanded : false
        },
        { name : this.$t('wsu.settings.enrollment.programs.title') ,
          subtitle : this.$t('wsu.settings.enrollment.programs.description'),
          value : 'programs' ,
          icon : 'mdi-school-outline',
        },
        { name : this.$t('wsu.settings.enrollment.documents.title') ,
          subtitle : this.$t('wsu.settings.enrollment.documents.description'),
          value : 'documents' ,
          icon : 'mdi-text-box-check-outline',
        },
      ]
    },
  },
  methods : {
    ...mapActions('business',  [ 'CHECK_ALIAS' ]) ,
    ...mapActions('settings',  [
        'EDIT_BUSINESS_GENERAL_SETTINGS',
        'GET_BUSINESS_TRANSLATIONS_ARRAY'
    ]) ,

    generateCode() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';

      let code = '';

      // Generate two random letters
      for (let i = 0; i < 2; i++) {
        code += letters.charAt(Math.floor(Math.random() * letters.length));
      }

      // Generate four random numbers
      for (let i = 0; i < 6; i++) {
        code += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }

      return code;
    },

    async changeTranslation(type , lang , value ) {
      let data = {
        [type] : value,
        lang : lang
      }
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS(data)
      if ( !result ) {
        return
      }
    },

    async openDescription(expand) {
      let result = await this.GET_BUSINESS_TRANSLATIONS_ARRAY()
      if ( !result ) {
        return
      }
      this.langs = result.langs
      this.names = result.names
      this.descriptions = result.descriptions
      expand()
    },

    async saveAlias() {

      if ( this.aliasConfirmationCode !== this.aliasConfirmation ) {
        this.aliasConfirmationError = this.$t('WrongConfirmationCode')
        this.notify(this.$t('WrongConfirmationCode') , 'warning')
        return
      }

      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS({ alias : this.alias })
      if ( !result ) {
        return
      }
      this.notify(this.$t('BusinessAliasChanged') , 'success')
      this.$store.state.business.selectedBusiness.alias = this.alias
      this.$router.push(this.businessDashLink('settings/general'))
      location.reload()
    },
    async openAliasChangeDialog() {
      if ( !this.alias ) {
        this.aliasError = this.$t('PleaseEnterBusinessAlias')
        this.notify(this.$t('PleaseEnterBusinessAlias') , 'warning')
        return
      }
      if ( !this.alias.match("^[a-zA-Z0-9_-]*$")) {
        this.aliasError = this.$t('AliasRegexError')
        return this.notify(this.$t('AliasRegexError'), 'warning')
      }

      if ( this.alias.length < 3) {
        this.aliasError = this.$t('AliasLengthError')
        return this.notify(this.$t('AliasLengthError'), 'warning')
      }

      if ( !await this.CHECK_ALIAS(this.alias) ) {
        this.aliasError = this.$t('BusinessAliasAlreadyExist')
        return this.notify(this.$t('BusinessAliasAlreadyExist'), 'warning')
      }

      this.aliasConfirmation = ''
      this.aliasConfirmationCode = this.generateCode()
      this.displayAliasDialog = true

    },
    openAliasDialog(expand) {
      this.alias = this.$store.state.business.selectedBusiness.alias
      expand()
    },
    addDescriptionLanguage() {

    },
    addTitleLanguage() {

    },
    initPage() {
      this.serviceKeys.gTag = !!this.$store.state.business.selectedBusiness.gTag
      this.serviceKeys.fPixelId = !!this.$store.state.business.selectedBusiness.fPixelId
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>